<nz-layout class="app-layout">
    <nz-sider class="menu-sidebar"
      nzCollapsible
      nzWidth="220px"
      nzBreakpoint="md"
      [(nzCollapsed)]="isCollapsed"
      [nzCollapsedWidth] = '0'
      [nzTrigger]="null">
      <div class="sidebar-logo" *ngIf="app != 'Cadlab3d'">
        <img [src]="logoUrl" style="width: 150px; height: 68px;" *ngIf="!isCollapsed">
        <img [src]="logoUrl" style="width: 40px;" *ngIf="isCollapsed">
      </div>
      <div class="sidebar-logo" *ngIf="app == 'Cadlab3d'">
        <img [src]="logoUrl" style="width: 92px; height: 68px;" *ngIf="!isCollapsed">
        <img [src]="logoUrl" style="width: 40px;" *ngIf="isCollapsed">
      </div>
   
      <ul nz-menu nzTheme="light" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
        <ng-container *ngFor="let item of menuDataActivated">
          <ng-container *ngIf="item.group == true">
            <li nz-submenu [nzTitle]="item.title" [nzIcon] = "item.icon">
              <ul>
                <ng-container *ngFor="let subitem of item.subItem">
                  <li  nz-menu-item nzMatchRouter [routerLink]="subitem.url" [queryParams]="subitem.query" (click)="checkCollapse()">
                    <i *ngIf="subitem.icon" [ngClass]="[subitem.icon, 'icon']" nz-icon [nzType]="subitem.icon" nzTheme="outline"></i>
                    <span class="title">{{subitem.title}}</span>
                  </li>
                </ng-container>

              </ul>
            </li>
          
          </ng-container>
          <ng-container *ngIf="item.group == false">
            <li  nz-menu-item nzMatchRouter [routerLink]="item.url"  [queryParams]="item.query" (click)="checkCollapse()">
              <i *ngIf="item.icon" [ngClass]="[item.icon, 'icon']" nz-icon [nzType]="item.icon" nzTheme="outline"></i>
              <span class="title">{{item.title}}</span>
            </li>
          </ng-container>
          
        </ng-container>
        
      </ul>
      <span class="version">Copyright © Easybox All rights reserved V{{version}}</span>
    </nz-sider>
    <nz-layout>
      <nz-header>
        <div class="app-header">
          <div nz-row >
            <div nz-col nzSpan = "1" style="text-align: left;">
              <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
                <span class="trigger"
                   nz-icon
                   [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
                ></span>
              </span>
            </div>
            <div nz-col nzSpan="23" style="text-align: right;">
              <app-top-menu></app-top-menu>
            </div>
          </div>
          
          
        </div>
      </nz-header>
      <nz-content>
        <div class="inner-content">
          <router-outlet></router-outlet>
        </div>
      </nz-content>
    </nz-layout>
  </nz-layout>
  