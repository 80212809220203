import { NgModule } from '@angular/core'
import { LayoutAuthComponent } from './auth/auth.component'
import { SharedModule } from '../shared.module'
import { MainComponent } from './main/main.component'
import { LeftmenuComponent } from './leftmenu/leftmenu.component'
import { AntdModule } from '../antd.module'
import { TopMenuComponent } from './top-menu/top-menu.component'


@NgModule({
  imports: [SharedModule],
  declarations: [LayoutAuthComponent, MainComponent, LeftmenuComponent, TopMenuComponent],
  exports: [LayoutAuthComponent, MainComponent],
})
export class LayoutsModule {}
