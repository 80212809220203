export enum MessageType {
  NotificationUnread = 'notification_unread',
  Ping = 'ping',
  Pong = 'pong',
}

export class WsMessage {
  type: MessageType

  constructor(data?: any) {
    this.type = data?.type
  }
}
