import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AppComponent } from './app.component'
import { AuthGuard } from './services/auth/auth.guard'
import { LayoutAuthComponent } from './layouts/auth/auth.component'
import { SharedModule } from './shared.module'

import { MainComponent } from './layouts/main/main.component'
import { LayoutsModule } from './layouts/layouts.module'

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'customers',
    pathMatch: 'full',
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'cases',
        loadChildren: () => import('./pages/case/case.module').then(m => m.CaseModule),
      },
      {
        path: 'products',
        loadChildren: () =>
          import('./pages/product/product.module').then(m => m.ProductModule),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./pages/account/account.module').then(m => m.AccountModule),
      },
      {
        path: 'customers',
        loadChildren: () =>
          import('./pages/customer/customer.module').then(m => m.CustomerModule),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./pages/product/profile/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'finance',
        loadChildren: () =>
          import('./pages/invoice/invoice.module').then(m => m.InvoiceModule),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./pages/dashboard/report.module').then(m => m.ReportModule),
      },
      {
        path: 'reports/customerReport',
        loadChildren: () =>
          import('./pages/customer-report/customer-report.module').then(m => m.CustomerReportModule),
      },
      {
        path: 'reports/userReport',
        loadChildren: () =>
          import('./pages/user-report/user-report.module').then(m => m.UserReportModule),
      },
      {
        path: 'announcements',
        loadChildren: () =>
          import('./pages/plash-ads/plash-ads-notifications.module').then(m => m.PlashAdsModule),
      },
      {
        path: 'automations',
        loadChildren: () =>
          import('./pages/automation/automation.module').then(m => m.AutomationModule),
      }
    ],
  },
  {
    path: 'auth',
    component: LayoutAuthComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/auth',
  },
]

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes),
    LayoutsModule,
  ],
  providers: [],
  exports: [RouterModule],
})
export class AppRoutingModule {}
