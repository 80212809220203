import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { webSocket, WebSocketSubject } from 'rxjs/webSocket'
import { AuthService } from '../auth/auth.service'
import { tap, retryWhen, delay } from 'rxjs/operators'
import { MessageType, WsMessage } from '../../models/message.model'
import { Collection } from '../../models/collection.model'
import { MarkAsReadPayload, Notification, NotificationUnread } from '../../models/notification.model'

const PAGE = 1
const PER_PAGE = 10

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  subject?: WebSocketSubject<WsMessage>
  notificationUpdated = new Subject<WsMessage>()

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    @Inject('WEBSOCKET_URL') private wsUrl: string,
  ) {}

  private createWebSocket(uri: string) {
    return new Observable((observer: any ) => {
      try {
        const subject = webSocket(uri)

        // send heartbeat messages every 10 seconds
        const handler = setInterval(() => {
          subject.next({
            type: MessageType.Ping,
          })
        }, 10 * 1000)

        const subscription = subject.asObservable().subscribe(
          data => observer.next(data),
          error => observer.error(error),
          () => observer.complete(),
        )

        return () => {
          clearInterval(handler)
          if (!subscription.closed) {
            subscription.unsubscribe()
          }
        }
      } catch (error) {
        observer.error(error)
        return 
      }
    })
  }

  private handleIncomingMessage(msg: WsMessage) {
    switch (msg.type) {
      case MessageType.NotificationUnread:
        this.notificationUpdated.next(msg)
        break
      case MessageType.Pong:
        break
    }
  }

  connect() {
    this.createWebSocket(this.wsUrl + '/ws?authToken=' + this.authService.getToken())
      .pipe(
        retryWhen(errors =>
          errors.pipe(
            tap(err => {
              console.error('Got error', err)
            }),
            delay(10000),
          ),
        ),
      )
      .subscribe(
        data => this.handleIncomingMessage(new WsMessage(data)),
        err => console.error(err),
      )
  }

  disconnect(){
    
  }

  async getNotifications(page: number, perPage: number): Promise<Collection<Notification>> {
    if (!page || !perPage) {
      page = PAGE
      perPage = PER_PAGE
    }

    const queryParams = `page=${page}&perPage=${perPage}`
    const r = await this.http.get(`/api/notifications?${queryParams}`).toPromise()
    return new Collection(r, Notification)
  }

  async getUnreadCount(): Promise<NotificationUnread> {
    const r = await this.http.get(`/api/notifications/unread`).toPromise()
    return new NotificationUnread(r)
  }

  async markAsRead(payload: MarkAsReadPayload) {
    await this.http.post(`/api/notifications/set_read`, payload).toPromise()
  }
}
