export const getMenuData: any[] = [
   {
    title: 'Dashboard',
    key: 'dashboard',
    icon: 'bar-chart',
    group: false,
    url: '/dashboard',
    query: {}
  },
  {
    title: 'Cases',
    key: 'cases',
    icon: 'profile',
    group: false,
    url: '/cases',
    query: {
      page: 1,
      perPage: 20,
      isDesc: true,
      orderBy: 'id',
      status: 'All'
    }
  },
  {
    title: 'Customers',
    key: 'customers',
    icon: 'user',
    group: false,
    url: '/customers',
    query: {}
  },
  {
    title: 'Products',
    key: 'products',
    icon: 'table',
    group: false,
    url: '/products',
    query: {}
  },
  {
    title: 'Finance',
    key: 'finance',
    icon: 'file',
    group: false,
    url: '/finance',
    query: {}
  },
  {
    title: 'Users',
    key: 'users',
    icon: 'team',
    group: false,
    url: '/users',
    query: {}
  },
  {
    title: 'Announcements',
    key: 'announcements',
    icon: 'bell',
    group: false,
    url: '/announcements',
    query: {}
  },
  {
    title: 'Automations',
    key: 'automations',
    icon: 'thunderbolt',
    group: false,
    url: '/automations',
    query: {}
  },
  {
    title: 'Reports',
    key: 'reports',
    icon: 'pie-chart',
    group: true,
    subItem: [{
      title: 'Customer Reports',
      key: 'customer_report',
      icon: 'pie-chart',
      group: false,
      url: 'reports/customerReport',
      query: {}
    },
    {
      title: 'User Reports',
      key: 'user_report',
      icon: 'line-chart',
      group: false,
      url: 'reports/userReport',
      query: {}
    },]
    
  },

]
