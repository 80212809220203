import { Component } from '@angular/core';
import { BackOfficeUserRole, User } from '../../models/user.model';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UserService } from '../../services/user/user.service';
import { MenuService } from '../../services/menu';
import { filter } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../../../environments/environment';
import { ThisReceiver } from '@angular/compiler';
import { SiteInfoService } from '../../services/site-info/site-info.service';
import { UtilService } from '../../services/util.service';
import { Title } from '@angular/platform-browser';

const BO_NAME = 'Back Office';
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
})
export class MainComponent {
  isCollapsed = false;

  isMobileView: boolean = false;
  leftMenuWidth: number = 0;
  isMenuCollapsed: boolean = false;
  logo: string = '';
  menuData: any[] = [];
  menuDataActivated: any[] = [];
  sessionUser: User | null = null;
  role: any;
  logoUrl = environment.logo;
  appName = environment.app;
  app = environment.app;
  version = environment.appVersion;
  currentParrent = '';
  siteName = BO_NAME;

  favIcon: any = document.querySelector('#favIcon');
  favIconUrl = environment.favi;
  constructor(
    private menuService: MenuService,
    private router: Router,
    private userService: UserService,
    private deviceService: DeviceDetectorService,
    private siteInfoService: SiteInfoService,
    private utilService: UtilService,
    private titleService: Title
  ) {
    this.menuService.getMenuData().subscribe((menuData) => {
      this.favIcon.href = this.favIconUrl;
      this.sessionUser = this.userService.getCurrentUser();
      this.role = this.sessionUser.role;
      this.menuData = menuData;
      if (this.role == BackOfficeUserRole.CADDesigner || this.role == BackOfficeUserRole.ExternalQC) {
        this.menuData = this.menuData.filter(
          (menu) =>
            menu.key != 'customers' &&
            menu.key != 'products' &&
            menu.key != 'finance' &&
            menu.key != 'users' &&
            menu.key != 'announcements' &&
            menu.key != 'reports' &&
            menu.key != 'automations'
        );
      }

      if (this.role == BackOfficeUserRole.QC) {
        this.menuData = this.menuData.filter(
          (menu) =>
            menu.key != 'finance' &&
            menu.key != 'category' &&
            menu.key != 'announcements' &&
            menu.key != 'reports' &&
            menu.key != 'automations'
        );
      }

      if (this.role == BackOfficeUserRole.Accountant) {
        this.menuData = this.menuData.filter(
          (menu) =>
            menu.key != 'users' &&
            menu.key != 'announcements' &&
            menu.key != 'reports' &&
            menu.key != 'automations'
        );
      }
    });
    this.favIcon.href = this.favIconUrl;
  }

  ngOnInit() {
    this.activateMenu(this.router.url);
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        let parrent = event.url.split('/')[2];
        console.log(event.url.split('/'));
        let change = false;
        if (this.currentParrent != parrent) {
          change = true;
          this.currentParrent = parrent;
        }
        if (change) {
          console.warn('change page');
        }

        const paths = event.url.split('/');
        if (paths && paths.length > 0) {
          const activeMenu = paths[1];
          this.menuDataActivated.forEach((menu) => {
            menu.selected = false;
            if (activeMenu.startsWith(menu.key)) {
              menu.selected = true;
              this.titleService.setTitle(
                this.appName + ' | ' + this.siteName + ' | ' + menu.title
              );
            }
          });
        }

        // this.activateMenu(event.url);
      });
    this.loadSiteInfo();
  }

  activateMenu(url: any, menuData = this.menuData) {
    menuData = JSON.parse(JSON.stringify(menuData));

    if (this.role == 'bo.cad_designer' && url == '/customers') {
      this.router.navigate(['dashboard']);
    }
    this.menuDataActivated = menuData.slice();

    let currentUrl = this.router.url;
    const paths = currentUrl.split('/');
    if (paths && paths.length > 0) {
      const activeMenu = paths[1];
      this.menuDataActivated.forEach((menu) => {
        menu.selected = false;
        if (activeMenu.startsWith(menu.key)) {
          menu.selected = true;
          this.titleService.setTitle(
            this.appName + ' | ' + this.siteName + ' | ' + menu.title
          );
        }
      });
    }

    //get current menu
  }

  selectedMenu(item: any) {
    this.router.navigate([item.url]);
    this.menuDataActivated.forEach((menu) => {
      menu.selected = false;
      if (menu.key == item.key) {
        menu.selected = true;
      }
    });
  }

  gotoAccountMgnt() {
    this.router.navigate(['account/account-listing']);
  }

  checkCollapse() {
    const isMobile = this.deviceService.isMobile();
    if (isMobile) {
      this.isCollapsed = !this.isCollapsed;
    }
  }

  async loadSiteInfo() {
    try {
      let sig = await this.siteInfoService.getSiteInfoGeneral();
      this.appName = sig.name;
    } catch (e) {
      this.utilService.showError(e);
    }
  }
}
