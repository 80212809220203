import { Inject, Injectable, Optional } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable } from 'rxjs'
import { AuthService } from '../services/auth/auth.service'

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    @Optional() @Inject('SERVICE_URL') private SERVICE_URL: string,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({ url: `${this.SERVICE_URL}${request.url}` })
    const accessToken = this.authService.getToken()

    request.headers.set('Content-Type', 'application/json')
    if (accessToken) {
      request.headers.set('Authorization', `Bearer ${accessToken}`)
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    }
    return next.handle(request)
  }
}
