import { User } from './user.model'

export enum EventType {
  EventTypeTicketCreated = 'ticket_created',
  EventTypeTicketStatusChanged = 'status_changed',
  EventTypeAssign = 'assign',
  EventTypeMention = 'mention',
}

export class Notification {
  id: number
  createdAt: Date
  author: User
  ticketId: number
  type: EventType
  read: boolean

  constructor(data?: any) {
    this.id = data?.id
    this.createdAt = data?.createdAt
    this.author = new User(data?.author)
    this.ticketId = data?.ticketId
    this.type = data?.type
    this.read = data?.read
  }
}

export class MarkAsReadPayload {
  markAll?: boolean
  notificationIds?: number[]
}

export class NotificationUnread {
  unreadCount: number

  constructor(data?: any) {
    this.unreadCount = data?.unreadCount
  }
}
