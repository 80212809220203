import { Component, OnInit } from '@angular/core';
import { User } from '../../models/user.model';
import { Notification } from '../../models/notification.model';
import { Router } from '@angular/router';
import { UserService } from '../../services/user/user.service';
import { AuthService } from '../../services/auth/auth.service';
import { NotificationService } from '../../services/notification/notification.service';
import { UtilService } from '../../services/util.service';
export enum EventType {
  EventTypeTicketCreated = 'ticket_created',
  EventTypeTicketStatusChanged = 'status_changed',
  EventTypeAssign = 'assign',
  EventTypeMention = 'mention',
  EventTypeComment = 'comment',
}

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrl: './top-menu.component.scss'
})
export class TopMenuComponent implements OnInit {
  sessionUser: User
  notifications: Notification[] = []
  unread: number = 0
  page: number = 1

  loadingNotifications: boolean = false 
  loadingMore: boolean = false 
  havingMore: boolean = false
  needLoadNotifications: boolean = true
  notificationVisible: boolean = false

  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private utilService: UtilService,
  ) {
    this.sessionUser = this.userService.getCurrentUser()
  }

  async ngOnInit() {
    this.notificationService.connect()
    this.notificationService.notificationUpdated.subscribe(msg => {
      this.loadUnreadCount()
      this.needLoadNotifications = true
    })

    this.loadUnreadCount()
  }

  async loadUnreadCount() {
    try {
      const r = await this.notificationService.getUnreadCount()
      this.unread = r.unreadCount
    } catch (e) {
      this.utilService.showError(e)
    }
  }

  async loadNotifications() {
    this.loadingNotifications = true
    let pageSize = 20
    try {
      const res: any = await this.notificationService.getNotifications(this.page, pageSize)
      if (res.data) {
        this.notifications = res.data
      }
      this.havingMore = this.notifications.length < res.totalCount
      this.needLoadNotifications = false
    } catch (e) {
      this.utilService.showError(e)
    }

    this.loadingNotifications = false
  }

  async loadMore() {
    try {
      let pageSize = 20
      this.page++
      const res: any = await this.notificationService.getNotifications(this.page, pageSize)
      this.notifications = this.notifications.concat(res.data)
      this.havingMore = this.notifications.length < res.totalCount
    } catch (e) {
      this.utilService.showError(e)
    }
  }

  async markAllAsRead() {
    try {
      await this.notificationService.markAsRead({
        markAll: true,
      })

      this.needLoadNotifications = true
      this.loadUnreadCount()
      this.loadNotifications()
    } catch (e) {
      this.utilService.showError(e)
    }
  }

  async markAsRead(notificationId: number) {
    try {
      await this.notificationService.markAsRead({
        notificationIds: [notificationId],
      })

      this.needLoadNotifications = true
      this.loadUnreadCount()
      this.loadNotifications()
    } catch (e) {
      this.utilService.showError(e)
    }
  }

  userMenuVisibleChange(visible: boolean) {
    if (visible) {
      this.sessionUser = this.userService.getCurrentUser()
    }
  }

  notificationFeedVisibleChange(visible: boolean) {
    if (visible && this.needLoadNotifications) {
      this.page = 1
      this.loadNotifications()
    }
  }

  viewTicket(notification: Notification) {
    this.notificationVisible = false
    this.markAsRead(notification.id)
    this.router.navigate([`cases/detail/${notification.ticketId}`])
  }

  profile() {
    this.router.navigate(['profile'])
  }

  logout() {
    this.authService.logout()
  }
}
