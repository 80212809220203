import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate } from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from './auth.service'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  authorized: boolean = false

  constructor(public router: Router, private authService: AuthService) {
    console.log('Token == CanActivate ')
  }
  

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    console.log('Token == ', this.authService.getToken())
    if (environment.authenticated) {
      return true
    }

    if (this.authService.getToken()) {
      return true
    }
    console.log('Token == ', this.authService.getToken())

    this.router.navigate(['auth/login'], { queryParams: { returnUrl: state.url } })
    return false
  }
}
